<template>
  <v-textarea
    :label="label"
    :value="value"
    :name="label"
    :placeholder="placeholder"
    outlined
    @input="input($event)"
    @blur="blur"
    color="rgba(0, 0, 0, 0.87)"
    :error-messages="valueErrors"
    :required="required"
    :no-resize="!resizable"
    :rows="rows"
    :class="customClass"
  >
  </v-textarea>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
export default {
  props: {
    value: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: false,
      default: ""
    },
    required: {
      type: Boolean,
      required: false,
      default: false
    },
    placeholder: {
      type: String,
      required: false,
      default: ""
    },
    resizable: {
      type: Boolean,
      required: false,
      default: false
    },
    rows: {
      type: String,
      required: false,
      default: "3"
    },
    customClass: {
      type: String,
      required: false,
      default: ""
    }
  },
  mixins: [validationMixin],
  methods: {
    input(e) {
      this.$emit("input", e);
      this.$v.value.$touch();
    },
    blur() {
      this.$v.value.$touch();
    }
  },
  validations() {
    return {
      value: { required: this.required ? required : false }
    };
  },
  computed: {
    valueErrors() {
      const errors = [];
      if (!this.$v.value.$dirty) return errors;
      !this.$v.value.required && errors.push(`${this.label} is required`);
      return errors;
    }
  }
};
</script>
